import ChartCard from './ChartCard';
import ReactApexChart from 'react-apexcharts';
import React, {useState} from 'react';
import axios from 'axios';
import useSWR from 'swr';
import {
  Flex,
  Input,
  InputGroup, NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper, Select, Stack, Text,
} from '@chakra-ui/react';

export default function CustomizableLineChart({title, api, options}) {
  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true});
  };

  const [range, setRange] = useState(31);
  const [interval, setInterval] = useState('d');

  const {data: data, mutate: mutate} = useSWR(`${api}/${range}${interval}`, fetcher);

  return (<ChartCard
    title={title}
    headerExtras={(<Flex style={{marginLeft: 'auto'}}>
      <Text fontSize='lg' fontWeight='light' verticalAlign="baseline" pt="6px" mr="2">
                Period:
      </Text>
      <NumberInput variant='flushed' value={range} maxW={16} onChange={(e) => setRange(e)}
        onBlur={(e) => mutate()}>
        <NumberInputField/>
        <NumberInputStepper>
          <NumberIncrementStepper/>
          <NumberDecrementStepper/>
        </NumberInputStepper>
      </NumberInput>
      <Stack spacing={3}>
        <Select value={interval} variant='flushed' size='md' onChange={(e) => setInterval(e.target.value)}
          onBlur={(e) => mutate()}>
          <option value='ms'>Milliseconds</option>
          <option value='s'>Seconds</option>
          <option value='m'>Minutes</option>
          <option value='h'>Hours</option>
          <option value='d'>Days</option>
          <option value='w'>Weeks</option>
          <option value='M'>Months</option>
          <option value='Q'>Quarters</option>
          <option value='y'>Years</option>
        </Select>
      </Stack>
    </Flex>)}
  >
    <ReactApexChart
      options={options}
      series={data?.data ? data.data : []}
      type="area"
      width="100%"
      height="100%"
    />
  </ChartCard>);
}
