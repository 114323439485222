// Chakra imports
import {Button, Flex, Grid, Link, SimpleGrid, useQuery} from '@chakra-ui/react';
import React, {useState} from 'react';
import useSWR from 'swr';
import axios from 'axios';
import Dashboard from '../../../layouts/Admin';
import DormCard from './components/DormCard';
import MiniCard from './components/MiniCard';
import MiniStatistics from '../Dashboard/components/MiniStatistics';
import {MdPostAdd} from 'react-icons/md';
import {BsFillReplyFill} from 'react-icons/bs';
import {AiOutlinePlusSquare} from 'react-icons/ai';
import DateLineChart from '../Dashboard/components/CustomizableLineChart';
import {useHistory, useParams} from 'react-router-dom';
import BoringCard from './components/BoringCard';
import {ExternalLinkIcon} from '@chakra-ui/icons';

function Dorm() {
  const {dorm: dormId} = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);


  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true});
  };
  const {data, mutate} = useSWR(`${process.env.REACT_APP_API_URL}/admin/dorms/${dormId}`, fetcher);

  console.log(data);
  if (!data) return (<></>);

  const dorm = data.data;

  function deleteDorm() {
    if (loading) return;
    if (!confirm('Are you sure you want to delete this dorm?')) return;
    setLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/dorms/${dormId}/delete`, {}, {withCredentials: true})
        .then(async (res) => {
          await mutate();
          setLoading(false);
        });
  }

  return (
    <Dashboard title={dorm.name}>
      <Flex flexDirection='column' pt={{base: '120px', md: '75px'}}>
        <SimpleGrid columns={{sm: 1, md: 2, xl: 4}} spacing='24px'>
          <MiniCard title="Posted By" value={dorm.owner.email}/>
          <MiniCard title="city" value={dorm.city}/>
          <MiniCard title="price" value={'€'+dorm.price}/>
          <MiniCard title="Title" value={dorm.title}/>
        </SimpleGrid>
        <Grid
          templateColumns={{md: '1fr', lg: '1.8fr 1.2fr'}}
          templateRows={{md: '1fr auto', lg: '1fr'}}
          my='26px'
          gap='24px'>
          <BoringCard title="Description">
            {dorm.description}
          </BoringCard>
          <BoringCard title="Photos">
            {dorm.images.map((image, index) => (<><Link href={image.thumb.url} isExternal>
                            Image #{index+1} <ExternalLinkIcon mx='2px' />
            </Link> <br /></>))}
          </BoringCard>
        </Grid>
        <Grid
          templateColumns={{sm: '1fr', lg: '1.5fr 1.5fr'}}
          templateRows={{sm: 'repeat(2, 1fr)', lg: '1fr'}}
          gap='24px'
          mb={{lg: '26px'}}>
        </Grid>

        <SimpleGrid columns={{sm: 2, md: 4, xl: 8}} spacing='24px'>
          <Button onClick={() => history.push(`/site/dorm/${dormId}`)}>View on Site</Button>
          <Button disabled>View User</Button>
          <Button onClick={() => deleteDorm()} colorScheme="red" isLoading={loading} disabled={dorm.deleted}>{dorm.deleted? 'Deleted' : 'Delete'}</Button>
        </SimpleGrid>
      </Flex>
    </Dashboard>
  );
}

export default Dorm;
