// Chakra imports
import {
  Checkbox,
  Flex, Input,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import IconBox from 'components/Icons/IconBox';
import React, {useState} from 'react';

const InputCard = React.forwardRef(({title, value, icon, optional = false, ...rest}, ref) => {
  const iconTeal = useColorModeValue('teal.300', 'teal.300');
  const textColor = useColorModeValue('gray.700', 'white');
  const [enabled, setEnabled] = useState(true);
  return (
    <Card minH='83px'>
      <CardBody style={!enabled ? {filter: 'grayscale(80%)'} : undefined}>
        <Flex flexDirection='row' align='center' justify='center' w='100%'>
          <Stat me='auto'>
            <Flex>
              <StatLabel
                fontSize='sm'
                color='gray.400'
                fontWeight='bold'
                pb='.1rem'>
                {title}
              </StatLabel>
            </Flex>
            <Flex>
              {optional && <Checkbox onChange={(e) => setEnabled(e.target.checked)} fontSize='lg' mr={3} size={'lg'} defaultChecked/>}

              <Input disabled={!enabled} fontSize='lg' color={textColor} defaultValue={value} ref={ref} {...rest}/>
            </Flex>
          </Stat>
          {icon && <IconBox as='box' h={'45px'} w={'45px'} bg={iconTeal}>
            {icon}
          </IconBox>}
        </Flex>
      </CardBody>
    </Card>
  );
});

export default InputCard;
