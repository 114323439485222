// Chakra imports
import {Flex} from '@chakra-ui/react';
import React from 'react';
import useSWR from 'swr';
import axios from 'axios';
import ReferralLinksTable from './components/ReferralLinks';

function Users() {
  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true});
  };
  const {data} = useSWR(`${process.env.REACT_APP_API_URL}/admin/referrallinks`, fetcher);

  if (!data) return (<></>);

  return (
    <Flex direction='column' pt={{base: '120px', md: '75px'}}>
      <ReferralLinksTable
        title={'Referral Links Table'}
        captions={['Link', 'Owner', 'Pending Payout', '']}
        data={data.data}
      />
    </Flex>
  );
}

export default Users;
