// Chakra imports
import {
  Button,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import TablesTableRow from 'components/Tables/TablesTableRow';
import React from 'react';
import ExternalDormRow from './ExternalDormRow';

const ExternalDormsTable = ({title, captions, data, pendingRoomCount = 0}) => {
  const textColor = useColorModeValue('gray.700', 'white');
  return (
    <Card overflowX={{sm: 'scroll', xl: 'hidden'}}>
      <CardHeader p='6px 0px 22px 0px'>
        <Text fontSize='xl' color={textColor} fontWeight='bold'>
          {title}
        </Text>
        <Button onClick={() => window.location.href = 'externaldorms/review'} ms={'auto'} colorScheme="facebook" disabled={pendingRoomCount < 1}>Review Pending Rooms ({pendingRoomCount})</Button>
      </CardHeader>
      <CardBody>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.8rem' pl='0px' color='gray.400'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? '0px' : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row) => {
              console.log(row.owner);
              return (
                <ExternalDormRow
                  key={`${row._id}`}
                  name={`${row.username}`}
                  date={row.postedAt}
                  city={row.city}
                  room={row._id}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default ExternalDormsTable;
