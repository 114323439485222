/* !

=========================================================
* Purity UI Dashboard - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/purity-ui-dashboard/blob/master/LICENSE.md)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Route, Switch, Redirect, BrowserRouter, useParams} from 'react-router-dom';

import AuthLayout from 'layouts/Auth.js';
import AdminLayout from 'layouts/Admin.js';
import {UserProvider} from './helpers/user-context';
import Dorm from './views/Dashboard/Dorm';
import User from './views/Dashboard/User';
import ReferralLink from './views/Dashboard/ReferralLink';
import ReviewExternalDorm from './views/Dashboard/ReviewExternalDorm';

ReactDOM.render(
    <UserProvider>
      <BrowserRouter>
        <Switch>
          {/* <Route path={`/auth`} component={AuthLayout} />*/}
          <Route path={`/admin/dorms/:dorm`} component={Dorm}/>
          <Route path={`/admin/users/:user`} component={User}/>
          <Route path={`/admin/referrallink/:link`} component={ReferralLink}/>
          <Route path={`/admin/externaldorms/review`} component={ReviewExternalDorm}/>

          <Route path={`/admin`} component={AdminLayout}/>
          <Route path='/site/dorm/:dorm' component={() => {
            const {dorm} = useParams();
            window.location.href = `${process.env.REACT_APP_MAIN_URL}/aanbod/${dorm}`;
            return null;
          }} />
          <Redirect from={`/`} to="/admin/dashboard"/>
        </Switch>
      </BrowserRouter>
    </UserProvider>,
    document.getElementById('root'),
);
