// Chakra imports
import {Button, Flex, Link, SimpleGrid} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import useSWR from 'swr';
import axios from 'axios';
import UsersTable from './components/ExternalDormsTable';
import ExternalDormsTable from './components/ExternalDormsTable';
import MiniCard from '../ReviewExternalDorm/components/MiniCard';
import {ExternalLinkIcon} from '@chakra-ui/icons';
import InputCard from '../ReviewExternalDorm/components/InputCard';
import ScraperCards from './components/ScraperCards';

function Dorms() {
  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true});
  };
  const {data} = useSWR(`${process.env.REACT_APP_API_URL}/admin/external/dorms`, fetcher, {});


  const [loading, setLoading] = React.useState(false);
  const [dorms, setDorms] = React.useState([]);
  useEffect(() => {
    if (data) {
      setDorms((c) => c.length === 0 ? data.data.dorms : c);
    }
  }, [data]);

  function loadMore() {
    setLoading(true);
    fetcher(`${process.env.REACT_APP_API_URL}/admin/external/dorms?skip=${dorms.length}`).then((res) => {
      setDorms([...dorms, ...res.data.dorms]);
      setLoading(false);
    });
  }

  if (!data) return (<></>);
  const pending = data.data.pending;

  return (
    <Flex direction='column' pt={{base: '120px', md: '75px'}}>
      <ScraperCards/>
      <ExternalDormsTable
        title={'Dorms Table'}
        captions={['Room', 'City', 'Uploader', 'Date', 'Replies', '']}
        data={dorms}
        pendingRoomCount={pending}
      />
      <Button isLoading={loading} onClick={loadMore} mt={5} colorScheme="blue" >Load More</Button>
    </Flex>
  );
}
export default Dorms;
