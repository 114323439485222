import MiniCard from './MiniCard';
import {Button, ButtonGroup, Progress, SimpleGrid} from '@chakra-ui/react';
import React from 'react';
import useSWR from 'swr';
import axios from 'axios';

export default function() {
  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true});
  };
  const {data, mutate} = useSWR(`${process.env.REACT_APP_API_URL}/admin/scraper/status`, fetcher, {refreshInterval: 1000});


  if (!data) return (<></>);
  const scraper = data.data;
  let status = scraper.running ? 'Running' : 'Idle';
  if (scraper.blocked) status = `Blocked (${scraper.blockedFor}s)`;


  return (<SimpleGrid columns={{sm: 1, md: 2, xl: 4}} spacing='24px' marginBottom={'15px'}>
    <MiniCard title="Scraper Status">
      {status} {!scraper.blocked && (scraper.added >= 0 ? `(${scraper.added} ${scraper.running ? 'added' : 'added last run'})` : '')}
    </MiniCard>
    <MiniCard title="Progress">
      <Progress value={scraper.current} max={scraper.total} size={'lg'} marginTop={'3'} isIndeterminate={!scraper.blocked && scraper.running && scraper.current === -1} colorScheme={scraper.blocked ? 'red' : undefined} />
      {/* {scraper.current}/{scraper.total}*/}
    </MiniCard>
    <MiniCard title="Current Group">
      {scraper.group}: {scraper.groupProgress}
    </MiniCard>
    <MiniCard title="Scraper Control">
      <ButtonGroup title="Scraper Actions">
        <Button colorScheme="blue" disabled={scraper.running} onClick={async () => {
          await axios.post(`${process.env.REACT_APP_API_URL}/admin/scraper/run`, {}, {withCredentials: true});
          mutate();
        }}>Start</Button>
        <Button colorScheme="red" disabled={!scraper.running} onClick={async () => {
          await axios.post(`${process.env.REACT_APP_API_URL}/admin/scraper/kill`, {}, {withCredentials: true});
          mutate();
        }}>Stop</Button>
      </ButtonGroup>
    </MiniCard>
  </SimpleGrid>);
}
