// Chakra imports
import {Button, Flex, Grid, Input, Link, SimpleGrid} from '@chakra-ui/react';
import React, {useRef, useState} from 'react';
import useSWR from 'swr';
import axios from 'axios';
import Dashboard from '../../../layouts/Admin';
import MiniCard from './components/MiniCard';
import {useHistory, useParams} from 'react-router-dom';
import BoringCard from './components/BoringCard';
import {ExternalLinkIcon} from '@chakra-ui/icons';
import InputCard from './components/InputCard';
import InputTextAreaCard from './components/InputTextAreaCard';
import {DatePicker} from '@orange_digital/chakra-datepicker';
import GptCard from './components/GptCard';

function ReviewExternalDorm() {
  const {dorm: dormId} = useParams();
  const history = useHistory();
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [loadingDiscard, setLoadingDiscard] = useState(false);
  const [loadingSkip, setLoadingSkip] = useState(false);


  // Refs of fields.
  const cityRef = useRef();
  const priceRef = useRef();
  const titleRef = useRef();
  const descriptionRef = useRef();
  const availableFromRef = useRef();
  const postedByRef = useRef();
  const sizeRef = useRef();
  const housematesRef = useRef();


  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true});
  };
  const {data, mutate} = useSWR(`${process.env.REACT_APP_API_URL}/admin/external/review`, fetcher, {
    revalidateAll: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: false,
    revalidateFirstPage: true,
  });

  console.log(data);
  if (!data) return (<></>);

  const dorm = data.data.dorm;
  const pending = data.data.pending; // int

  function discard() {
    setLoadingDiscard(true);
    axios.post(`${process.env.REACT_APP_API_URL}/admin/external/review`, {
      action: 'DISCARD',
      id: data.data.id,
    }, {withCredentials: true})
        .then(async (res) => {
          if (pending > 0) window.location.reload();
          else history.push('/admin/externaldorms');
        });
  }

  function save() {
    setLoadingPublish(true);
    const r = {
      action: 'PUBLISH',
      id: data.data.id,
      values: {
        availableFrom: availableFromRef.current.disabled ? null : availableFromRef.current.value,
        title: titleRef.current.value,
        // username: postedByRef.current.value,
        city: cityRef.current.value,
        city_index: cityRef.current.value.toLowerCase(),
        price: priceRef.current.value,
        size: sizeRef.current.value,
        description: descriptionRef.current.value,
        housemates: housematesRef.current.value,
      },
    };

    axios.post(`${process.env.REACT_APP_API_URL}/admin/external/review`, r, {withCredentials: true})
        .then(async (res) => {
          if (pending > 0) window.location.reload();
          else history.push('/admin/externaldorms');
        });
  }

  function skip() {
    setLoadingSkip(true);
    axios.post(`${process.env.REACT_APP_API_URL}/admin/external/review`, {
      action: 'SKIP',
      id: data.data.id,
    }, {withCredentials: true})
        .then(async (res) => {
          if (pending > 0) window.location.reload();
          else history.push('/admin/externaldorms');
        });
  }

  return (
    <Dashboard title={`Reviewing Dorm #${dorm.id}`}>
      <Flex flexDirection='column' pt={{base: '120px', md: '75px'}}>
        {dorm.gpt_spam &&
             <Grid
               mb={{sm: '24px'}}>
               <GptCard title="ChatGPT Response">
          ChatGPT has flagged this post as spam.
               </GptCard>
             </Grid>}

        <SimpleGrid columns={{sm: 1, md: 2, xl: 4}} spacing='24px'>
          <MiniCard title="link">
            <Link href={dorm.link} isExternal>
              Facebook <ExternalLinkIcon mx='2px' />
            </Link>
          </MiniCard>
          <MiniCard title="Posted At" value={new Date(dorm.postedAt).toLocaleString()}/>

          <InputCard title="Available From" value={dorm.availableFrom?.split('T')[0] || dorm.postedAt.split('T')[0]} type={'date'} ref={availableFromRef} optional/> {/* TODO: fix this being optional*/}
          <InputCard title="Title" value={dorm.title} type={'text'} ref={titleRef}/>
          {/* <InputCard title="Posted By" value={dorm.username} type={'text'} ref={postedByRef}/>*/}
          <InputCard title="city" value={dorm.city} type={'text'} ref={cityRef}/>
          <InputCard title="price" value={dorm.price || -1} type={'number'} ref={priceRef}/>
          <InputCard title="size" value={dorm.size || -1} type={'number'} ref={sizeRef}/>
          <InputCard title="roommates" value={dorm.housemates || -1} type={'number'} ref={housematesRef}/>

        </SimpleGrid>
        <Grid
          templateColumns={{md: '1fr', lg: '1.8fr 1.2fr'}}
          templateRows={{md: '1fr auto', lg: '1fr'}}
          my='26px'
          gap='24px'>
          <InputTextAreaCard title="Description" value={dorm.description} type={'textarea'} ref={descriptionRef}/>

          <BoringCard title="Photos">
            {dorm.images?.map((image, index) => (<><Link href={image} isExternal>
                            Image #{index+1} <ExternalLinkIcon mx='2px' />
            </Link> <br /></>))}
          </BoringCard>
        </Grid>
        <Grid
          templateColumns={{sm: '1fr', lg: '1.5fr 1.5fr'}}
          templateRows={{sm: 'repeat(2, 1fr)', lg: '1fr'}}
          gap='24px'
          mb={{lg: '26px'}}>
        </Grid>

        <SimpleGrid columns={{sm: 2, md: 4, xl: 8}} spacing='24px'>
          <Button disabled={loadingDiscard || loadingPublish || loadingSkip} isLoading={loadingPublish} onClick={save} colorScheme={'green'}>Save & Publish</Button>
          <Button disabled={loadingDiscard || loadingPublish || loadingSkip} isLoading={loadingDiscard} onClick={discard} colorScheme="red">Discard</Button>
          <Button disabled={loadingDiscard || loadingPublish || loadingSkip} isLoading={loadingSkip} onClick={skip}>Skip</Button>
          <Button disabled={false} colorScheme={'blue'}>{pending} rooms left...</Button>
        </SimpleGrid>
      </Flex>
    </Dashboard>
  );
}

export default ReviewExternalDorm;
