// Chakra Icons
import {BellIcon, MoonIcon, SearchIcon} from '@chakra-ui/icons';
// Chakra Imports
import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text, useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
// Assets
import avatar1 from 'assets/img/avatars/avatar1.png';
import avatar2 from 'assets/img/avatars/avatar2.png';
import avatar3 from 'assets/img/avatars/avatar3.png';
// Custom Icons
import {ProfileIcon, SettingsIcon} from 'components/Icons/Icons';
// Custom Components
import {ItemContent} from 'components/Menu/ItemContent';
import SidebarResponsive from 'components/Sidebar/SidebarResponsive';
import PropTypes from 'prop-types';
import React from 'react';
import {NavLink} from 'react-router-dom';
import routes from 'routes.js';

export default function HeaderLinks(props) {
  const {variant, children, fixed, secondary, onOpen, ...rest} = props;

  // Chakra Color Mode
  const mainTeal = useColorModeValue('teal.300', 'teal.300');
  const inputBg = useColorModeValue('white', 'gray.800');
  let mainText = useColorModeValue('gray.700', 'gray.200');
  let navbarIcon = useColorModeValue('gray.500', 'gray.200');
  const searchIcon = useColorModeValue('gray.700', 'gray.200');
  const {colorMode, toggleColorMode} = useColorMode();
  if (secondary) {
    navbarIcon = 'white';
    mainText = 'white';
  }
  const settingsRef = React.useRef();
  return (
    <Flex
      pe={{sm: '0px', md: '16px'}}
      w={{sm: '100%', md: 'auto'}}
      alignItems="center"
      flexDirection="row"
    >
      <InputGroup
        cursor="pointer"
        bg={inputBg}
        borderRadius="15px"
        w={{
          sm: '128px',
          md: '200px',
        }}
        me={{sm: 'auto', md: '20px'}}
        _focus={{
          borderColor: {mainTeal},
        }}
        _active={{
          borderColor: {mainTeal},
        }}
      >
      </InputGroup>
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        // logo={logo}
        {...rest}
      />
      <MoonIcon
        cursor="pointer"
        ms={{base: '16px', xl: '0px'}}
        me="16px"
        onClick={toggleColorMode}
        color={navbarIcon}
        w="18px"
        h="18px"
      />
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
