// Chakra imports
import {Flex, SimpleGrid} from '@chakra-ui/react';
import React from 'react';
import useSWR from 'swr';
import axios from 'axios';
import Dashboard from '../../../layouts/Admin';
import MiniCard from './components/MiniCard';
import {useParams} from 'react-router-dom';

function Dorm() {
  const {user: userId} = useParams();

  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true});
  };
  const {data} = useSWR(`${process.env.REACT_APP_API_URL}/admin/users/${userId}`, fetcher);

  if (!data) return (<></>);

  const user = data.data;

  return (
    <Dashboard title={user.email}>
      <Flex flexDirection='column' pt={{base: '120px', md: '75px'}}>
        <SimpleGrid columns={{sm: 1, md: 2, xl: 4}} spacing='24px'>
          <MiniCard title="Name" value={`${user.nameFirst} ${user.nameLast}`}/>
          <MiniCard title="Gender" value={user.gender}/>
          <MiniCard title="Email" value={user.email}/>
          <MiniCard title="Residence" value={user.residence}/>
          <MiniCard title="Birthday" value={user.birthdate}/>
          <MiniCard title="Institution" value={user.institution}/>
          <MiniCard title="Major" value={user.major}/>
          <MiniCard title="Level" value={user.level}/>
          <MiniCard title="Phone Number" value={user.phoneNumber}/>
          <MiniCard title="Created At" value={Intl.DateTimeFormat(undefined, {dateStyle: 'short', timeStyle: 'medium'}).format(new Date(user.createdAt))}/>

          <MiniCard title="Email Verified" value={user.emailVerified ? 'verified' : 'unverified'}/>
          <MiniCard title="Admin Status" value={user.admin ? 'true' : 'false'}/>

        </SimpleGrid>
      </Flex>
    </Dashboard>
  );
}

export default Dorm;
