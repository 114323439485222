// Chakra imports
import {
  Button,
  Flex, FormControl, FormLabel,
  Grid, Input,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  useQuery,
} from '@chakra-ui/react';
import React, {useRef, useState} from 'react';
import useSWR from 'swr';
import axios from 'axios';
import Dashboard from '../../../layouts/Admin';
import DormCard from './components/DormCard';
import MiniCard from './components/MiniCard';
import MiniStatistics from '../Dashboard/components/MiniStatistics';
import {MdPostAdd} from 'react-icons/md';
import {BsFillReplyFill} from 'react-icons/bs';
import {AiOutlinePlusSquare} from 'react-icons/ai';
import DateLineChart from '../Dashboard/components/CustomizableLineChart';
import {useHistory, useParams} from 'react-router-dom';
import BoringCard from './components/BoringCard';
import {ExternalLinkIcon} from '@chakra-ui/icons';

function ReferralLink() {
  const {link: linkId} = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const inputRef = useRef();


  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true});
  };
  const {data, mutate} = useSWR(`${process.env.REACT_APP_API_URL}/admin/referrallink/${linkId}`, fetcher);

  if (!data) return (<></>);

  const link = data.data;

  async function payout(amount) {
    if (confirm('Are you sure you want to payout '+amount+' to ' + link.owner.email)) {
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/referrallink/${linkId}/payout`, {amount: amount}, {withCredentials: true});
      window.location.reload(false);
    }
  }

  return (
    <Dashboard title={link.id}>
      <Flex flexDirection='column' pt={{base: '120px', md: '75px'}}>
        <SimpleGrid columns={{sm: 1, md: 3, xl: 3}} spacing='24px'>
          <MiniCard title="link" value={`${process.env.REACT_APP_MAIN_URL}/?group=${link.id}`}/>
          <MiniCard title="owner" value={link.owner.email}/>
          <MiniCard title="pending payout" value={'€'+link.pendingPayout}/>
          <MiniCard title="signups" value={link.signups}/>
          <MiniCard title="rooms created" value={link.roomscreated}/>
          <MiniCard title="rooms decided" value={link.roomsdecided}/>
        </SimpleGrid>

        <SimpleGrid columns={{sm: 2, md: 4, xl: 8}} spacing='24px' pt={5}>
          <Button onClick={() => setOpen(true)} colorScheme="green" isLoading={isOpen}>Payout</Button>
        </SimpleGrid>

        <Modal
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Register Payout</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Amount</FormLabel>
                <Input ref={inputRef} type={'number'} min={0} max={link.pendingPayout} defaultValue={link.pendingPayout}/>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='green' mr={3} isLoading={loading} onClick={() => payout(inputRef.current.value)}>
                Payout
              </Button>
              <Button onClick={onClose} disabled={loading}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Dashboard>
  );
}

export default ReferralLink;
