// import
import Dashboard from 'views/Dashboard/Dashboard';
import Tables from 'views/Dashboard/Tables';
import Billing from 'views/Dashboard/Billing';

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
} from 'components/Icons/Icons';
import Users from './views/Dashboard/Users';
import Dorms from './views/Dashboard/Dorms';
import ReferralLinks from './views/Dashboard/ReferralLinks';
import ExternalDorms from "./views/Dashboard/ExternalDorms";

const dashRoutes = [
  {
    path: '/dashboard',
    name: 'Overview',
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/users',
    name: 'Users',
    icon: <StatsIcon color="inherit" />,
    component: Users,
    layout: '/admin',
  },

  {
    path: '/dorms',
    name: 'Dorms',
    icon: <StatsIcon color="inherit" />,
    component: Dorms,
    layout: '/admin',
  },
  {
    path: '/referrallinks',
    name: 'Referral Links',
    icon: <StatsIcon color="inherit" />,
    component: ReferralLinks,
    layout: '/admin',
  },
  {
    path: '/externaldorms',
    name: 'External Dorms',
    icon: <StatsIcon color="inherit" />,
    component: ExternalDorms,
    layout: '/admin',
  },
  // {
  //   path: "/billing",
  //   name: "Billing",
  //   icon: <CreditIcon color="inherit" />,
  //   component: Billing,
  //   layout: "/admin",
  // }
];
export default dashRoutes;
