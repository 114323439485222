// Chakra imports
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import TablesTableRow from 'components/Tables/TablesTableRow';
import React, {useRef, useState} from 'react';
import ReferralLinkRow from './ReferralLinkRow';
import axios from 'axios';
import id from 'moment/locale/id';

const ReferralLinksTable = ({title, captions, data}) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const onClose = () => setOpen(false);

  const ownerRef = useRef();
  const idRef = useRef();

  async function createRef(owner, id) {
    if (!confirm('Are you sure you want to create a referral link for ' + owner + ' with id ' + id + '?')) {
      return;
    }
    setLoading(true);
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/admin/referrallink`, {id: id, owner: owner}, {withCredentials: true});
    if (res.data.type == 'error') {
      alert(res.data.message);
      setLoading(false);
    } else {
      window.location.reload(false);
    }
  };

  return (
    <Card overflowX={{sm: 'scroll', xl: 'hidden'}}>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Register Payout</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Owner</FormLabel>
              <Input ref={ownerRef} placeholder={'example@myroom.nl'} type={'email'}/>
            </FormControl>
            <FormControl>
              <FormLabel>Link ID</FormLabel>
              <Input ref={idRef} placeholder={'somegroup'} type={'text'}/>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='green' mr={3} isLoading={loading} onClick={() => createRef(ownerRef.current.value, idRef.current.value)}>
              Create
            </Button>
            <Button onClick={onClose} disabled={loading}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <CardHeader p='6px 0px 22px 0px'>
        <Text fontSize='xl' color={textColor} fontWeight='bold'>
          {title}
        </Text>
        <Button onClick={() => setOpen(true)} ms={'auto'} colorScheme="facebook">Create Referral Link</Button>
      </CardHeader>
      <CardBody>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.8rem' pl='0px' color='gray.400'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? '0px' : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row) => {
              return (
                <ReferralLinkRow
                  key={`${row._id}`}
                  id={row.id}
                  email={row.owner.email}
                  pendingPayout={row.pendingPayout}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default ReferralLinksTable;
