// Chakra imports
import {Flex, Grid, Image, SimpleGrid, useColorModeValue} from '@chakra-ui/react';
// assets
import peopleImage from 'assets/img/people-image.png';
import logoChakra from 'assets/svg/logo-white.svg';
import BarChart from 'components/Charts/BarChart';
import LineChart from 'components/Charts/LineChart';
import ReactApexChart from 'react-apexcharts';
// Custom icons
import React, {useState} from 'react';
import {dashboardTableData, timelineData} from 'variables/general';
import ActiveUsers from './components/ActiveUsers';
import BuiltByDevelopers from './components/BuiltByDevelopers';
import MiniStatistics from './components/MiniStatistics';
import OrdersOverview from './components/OrdersOverview';
import Projects from './components/Projects';
import SalesOverview from './components/SalesOverview';
import WorkWithTheRockets from './components/WorkWithTheRockets';
import {AiOutlineFolderView, AiOutlinePlusSquare} from 'react-icons/ai';
import {BsFillReplyFill} from 'react-icons/bs';
import {MdPostAdd} from 'react-icons/md';
import useSWR from 'swr';
import axios from 'axios';
import ChartCard from './components/ChartCard';
import moment from 'moment';
import DateLineChart from './components/CustomizableLineChart';

export default function Dashboard() {
  const iconBoxInside = useColorModeValue('white', 'white');

  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true});
  };

  const {data: res} = useSWR(`${process.env.REACT_APP_API_URL}/admin/statistics/latest`, fetcher);

  // for (let i = signupRange -1; i >= 0; i--){
  //    signUpChartOptions.xaxis.categories.push(moment(moment.now()).subtract(i, 'days').format('ddd M MMM'))
  // }

  console.log(res);
  if (!res) {
    return (
      <>
            loading...
      </>
    );
  }

  const data=res.data;

  return (
    <Flex flexDirection='column' pt={{base: '120px', md: '75px'}}>
      <SimpleGrid columns={{sm: 1, md: 2, xl: 4}} spacing='24px'>
        <MiniStatistics
          title={'New Dorms This Week'}
          amount={data.dorms.latest}
          percentage={data.dorms.compared}
          icon={<MdPostAdd size={24} color={iconBoxInside}/>}
        />
        <MiniStatistics
          title={'Replies This Week'}
          amount={data.replies.latest}
          percentage={data.replies.compared}
          icon={<BsFillReplyFill size={24} color={iconBoxInside}/>}
        />
        <MiniStatistics
          title={'New Sign Ups This Week'}
          amount={data.signups.latest}
          percentage={data.signups.compared}
          icon={<AiOutlinePlusSquare size={24} color={iconBoxInside}/>}
        />
        {/* <MiniStatistics*/}
        {/*    title={"Total Views This Week"}*/}
        {/*    amount={"32,532"}*/}
        {/*    percentage={-12}*/}
        {/*    icon={<AiOutlineFolderView size={24} color={iconBoxInside}/>}*/}
        {/* />*/}
      </SimpleGrid>
      <Grid
        templateColumns={{md: '1fr', lg: '1.8fr 1.2fr'}}
        templateRows={{md: '1fr auto', lg: '1fr'}}
        my='26px'
        gap='24px'>
      </Grid>
      <Grid
        templateColumns={{sm: '1fr', lg: '1.5fr 1.5fr'}}
        templateRows={{sm: 'repeat(2, 1fr)', lg: '1fr'}}
        gap='24px'
        mb={{lg: '26px'}}>
        {/* <ActiveUsers*/}
        {/*    title={"Active Users"}*/}
        {/*    percentage={23}*/}
        {/*    chart={<BarChart/>}*/}
        {/* />*/}
        <DateLineChart
          api={`${process.env.REACT_APP_API_URL}/admin/statistic/signups`}
          title="Signups Overview"
          options={{
            chart: {
              toolbar: {
                show: false,
              },
            },
            tooltip: {
              theme: 'dark',
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth',
            },
            xaxis: {
              type: 'datetime',
              labels: {
                style: {
                  colors: '#c8cfca',
                  fontSize: '12px',
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  colors: '#c8cfca',
                  fontSize: '12px',
                },
              },
            },
            legend: {
              show: false,
            },
            grid: {
              strokeDashArray: 5,
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                type: 'vertical',
                shadeIntensity: 0.5,
                gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                inverseColors: true,
                opacityFrom: 0.8,
                opacityTo: 0,
                stops: [],
              },
              colors: ['#4FD1C5', '#2D3748'],
            },
            colors: ['#4FD1C5', '#2D3748'],
          }}
        />
        <DateLineChart
          api={`${process.env.REACT_APP_API_URL}/admin/statistic/new-dorms_new-replies`}
          title="Dorms Overview"
          options={{
            chart: {
              toolbar: {
                show: false,
              },
            },
            tooltip: {
              theme: 'dark',
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth',
            },
            xaxis: {
              type: 'datetime',
              labels: {
                style: {
                  colors: '#c8cfca',
                  fontSize: '12px',
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  colors: '#c8cfca',
                  fontSize: '12px',
                },
              },
            },
            legend: {
              show: false,
            },
            grid: {
              strokeDashArray: 5,
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                type: 'vertical',
                shadeIntensity: 0.5,
                gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                inverseColors: true,
                opacityFrom: 0.8,
                opacityTo: 0,
                stops: [],
              },
              colors: ['#4FD1C5', '#2D3748'],
            },
            colors: ['#4FD1C5', '#2D3748'],
          }}
        />
      </Grid>
      <Grid
        templateColumns={{sm: '1fr', md: '1fr 1fr', lg: '2fr 1fr'}}
        templateRows={{sm: '1fr auto', md: '1fr', lg: '1fr'}}
        gap='24px'>
        {/* <Projects*/}
        {/*    title={"Projects"}*/}
        {/*    amount={30}*/}
        {/*    captions={["Companies", "Members", "Budget", "Completion"]}*/}
        {/*    data={dashboardTableData}*/}
        {/* />*/}
        {/* <OrdersOverview*/}
        {/*    title={"Orders Overview"}*/}
        {/*    amount={30}*/}
        {/*    data={timelineData}*/}
        {/* />*/}
      </Grid>
    </Flex>
  );
}
