import * as React from 'react';
import {useState, Fragment} from 'react';
import useSWR from 'swr';
import axios from 'axios';
import {Redirect} from 'react-router-dom';

const AuthContext = React.createContext(undefined);

function UserProvider(props) {
  const fetcher = async (url) => {
    const res = await axios.get(url, {withCredentials: true});
    return {
      success: true,
      user: (res.data === false) ? null : res.data,
    };
  };

  const {data, mutate: reloadUser, error} = useSWR(`${process.env.REACT_APP_API_URL}/users/me`, fetcher);
  console.log(data);

  if (error) {
    return (
      <Fragment>
        <span>The API is currently unreachable!</span>
      </Fragment>
    );
  }

  if (!data) {
    return <div></div>;
  }

  const user = data.user.login ? data.user.user : null;
  if (!user) {
    return (
      <>
                You're not logged in! Redirecting to {window.location.href = process.env.REACT_APP_MAIN_URL + '/auth/login'}
      </>
    );
  }
  if (!(user.admin === true)) {
    return (
      <>
                You don't have permission to view this page.
      </>
    );
  }
  return (
    <AuthContext.Provider value={{user, reloadUser}} {...props} />
  );
}

const useUser = () => React.useContext(AuthContext);

export {UserProvider, useUser};
