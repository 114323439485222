// Chakra imports
import {Flex} from '@chakra-ui/react';
import React from 'react';
import useSWR from 'swr';
import axios from 'axios';
import UsersTable from './components/DormsTable';
import DormsTable from './components/DormsTable';

function Dorms() {
  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true});
  };
  const {data} = useSWR(`${process.env.REACT_APP_API_URL}/admin/dorms`, fetcher);

  if (!data) return (<></>);

  return (
    <Flex direction='column' pt={{base: '120px', md: '75px'}}>
      <DormsTable
        title={'Dorms Table'}
        captions={['Room', 'City', 'Uploader', 'Date', 'Replies', '']}
        data={data.data}
      />
    </Flex>
  );
}

export default Dorms;
